import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
import { Image } from 'nordic/image';

const ICON_ID = 'rep_seller_attention_good_v2';
const namespace = 'ui-pdp-icon ui-pdp-icon--message-positive';

const IconMessagePositiveV2 = ({ className }) => (
  <div className={classnames(namespace, className)}>
    <Image src="message-positive-v2.svg" alt="" />
  </div>
);

IconMessagePositiveV2.propTypes = {
  className: string,
};

IconMessagePositiveV2.defaultProps = {
  className: null,
};

IconMessagePositiveV2.ICON_ID = ICON_ID;

export default React.memo(IconMessagePositiveV2);
export { IconMessagePositiveV2 };
